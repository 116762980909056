import type { FC, ReactNode } from 'react';
import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import type { User } from 'src/types/User';
import SplashScreen from 'src/components/SplashScreen';
import AuthResponse from '../network/auth/AuthResponse';
import UserResponse from '../network/UserResponse';
import moment from 'moment';
import OkResponse from '../network/OkResponse';
import axios from '../utils/axios';

interface AuthState {
	isInitialised: boolean;
	isAuthenticated: boolean;
	user: UserResponse | null;
}

interface AuthContextValue extends AuthState {
	method: 'JWT';
	login: (email: string, password: string) => Promise<void>;
	logout: () => void;
	forgotPassword: (email: string) => Promise<void>;
	resetPassword: (code: string, password: string, passwordConfirmation: string) => Promise<void>;
}

interface AuthProviderProps {
	children: ReactNode;
}

type InitialiseAction = {
	type: 'INITIALISE';
	payload: {
		isAuthenticated: boolean;
		user: UserResponse | null;
	};
};

type LoginAction = {
	type: 'LOGIN';
	payload: {
		user: UserResponse;
	};
};

type LogoutAction = {
	type: 'LOGOUT';
};

type ForgotPasswordAction = {
	type: 'FORGOT_PASSWORD';
};

type Action =
	| InitialiseAction
	| LoginAction
	| LogoutAction
	| ForgotPasswordAction;

const initialAuthState: AuthState = {
	isAuthenticated: false,
	isInitialised: false,
	user: null
};

const isValidToken = (accessToken: string): boolean => {
	if (!accessToken) {
		return false;
	}

	const decoded: any = jwtDecode(accessToken);
	const currentTime = moment.utc().unix();

	return decoded.exp > currentTime;
};

const setSession = (accessToken?: string, user?: User): void => {
	if (accessToken) {
		window.localStorage.setItem('accessToken', accessToken);
		if (user) {
			window.localStorage.setItem('user', JSON.stringify(user));
		}

		axios.defaults.headers.common.Authorization = `Bearer ${ accessToken }`;
	} else {
		window.localStorage.removeItem('accessToken');
		window.localStorage.removeItem('user');

		delete axios.defaults.headers.common.Authorization;
	}
};

const reducer = (state: AuthState, action: Action): AuthState => {
	switch (action.type) {
		case 'INITIALISE': {
			const { isAuthenticated, user } = action.payload;

			return {
				...state,
				isAuthenticated,
				isInitialised: true,
				user
			};
		}
		case 'LOGIN': {
			const { user } = action.payload;

			return {
				...state,
				isAuthenticated: true,
				user
			};
		}
		case 'LOGOUT': {
			return {
				...state,
				isAuthenticated: false,
				user: null
			};
		}

		case 'FORGOT_PASSWORD':
		default: {
			return { ...state };
		}
	}
};

const AuthContext = createContext<AuthContextValue>({
	...initialAuthState,
	method: 'JWT',
	login: () => Promise.resolve(),
	logout: () => {
	},
	forgotPassword: () => Promise.resolve(),
	resetPassword: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
	const [ state, dispatch ] = useReducer(reducer, initialAuthState);

	const login = async (email: string, password: string) => {
		// TODO: Connect it here with API server
		const response = await axios.post<AuthResponse>('/auth/sign-in', { email, password });
		const { accessToken, user } = response.data;

		setSession(accessToken, user);
		dispatch({
			type: 'LOGIN',
			payload: {
				user
			}
		});
	};

	const forgotPassword = async (email: string) => {
		await axios.post<OkResponse>('/auth/forgot-password', { email });

		dispatch({
			type: 'FORGOT_PASSWORD'
		});
	};

	const resetPassword = async (code: string, password: string, passwordConfirmation: string) => {
		const response = await axios.post<AuthResponse>('/auth/reset-password', { code, password, passwordConfirmation });
		const { accessToken, user } = response.data;

		setSession(accessToken, user);
		dispatch({
			type: 'LOGIN',
			payload: {
				user
			}
		});
	};

	const logout = () => {
		setSession(null);
		dispatch({ type: 'LOGOUT' });
	};

	useEffect(() => {
		const initialise = async () => {
			try {
				const accessToken = window.localStorage.getItem('accessToken');

				if (accessToken && isValidToken(accessToken)) {
					setSession(accessToken);

					const response = await axios.get<UserResponse>('/user');
					const user = response.data;

					dispatch({
						type: 'INITIALISE',
						payload: {
							isAuthenticated: true,
							user
						}
					});
				} else {
					dispatch({
						type: 'INITIALISE',
						payload: {
							isAuthenticated: false,
							user: null
						}
					});
				}
			} catch (err) {
				console.error(err);
				dispatch({
					type: 'INITIALISE',
					payload: {
						isAuthenticated: false,
						user: null
					}
				});
			}
		};

		initialise();
	}, []);

	if (!state.isInitialised) {
		return <SplashScreen />;
	}

	return (
		<AuthContext.Provider
			value={ {
				...state,
				method: 'JWT',
				login,
				logout,
				forgotPassword,
				resetPassword
			} }
		>
			{ children }
		</AuthContext.Provider>
	);
};

export default AuthContext;
