export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

// export const API_URL = 'http://192.168.1.101:8080';
// export const API_URL = 'http://127.0.0.1:8080'; //For localhost uncomment it first
export const API_URL = 'https://api.guidifyit.com';

export const GOOGLE_API_KEY = 'AIzaSyDEKZh1K3k11q0OoNikv5pc5SdQRut0Uhg';

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
};
