export enum Roles {
	BANNED,
	USER,
	ADMIN,
	SUPER_ADMIN
}

export const parseRole = ( role: Roles ) => {
	switch (role) {
		case Roles.USER:
			return 'USER';
		
		case Roles.ADMIN:
			return 'ADMIN';
		
		case Roles.SUPER_ADMIN:
			return 'SUPER_ADMIN';
	}
};

export interface PlatformUser {
	id: string;
	vendorId: number;
	name: string;
	email: string;
	phoneNumber: string;
	avatar: string;
	roleId: Roles;
	description: string;
	gamesLimit: number;
	guidesLimit: number;
	latitude: number;
	longitude: number;
	isSuspended: boolean;
}
