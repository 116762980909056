import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Box, Button, IconButton, makeStyles, SvgIcon, Toolbar } from '@material-ui/core';
import { Menu as MenuIcon, Unlock as UnlockIcon } from 'react-feather';
import { THEMES } from 'src/constants';
import type { Theme } from 'src/theme';
import Account from './Account';
import useAuth from '../../../hooks/useAuth';
import { matchPath, useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

interface TopBarProps {
	className?: string;
	onMobileNavOpen?: () => void;
}

const useStyles = makeStyles( ( theme: Theme ) => ( {
	root: {
		zIndex: theme.zIndex.drawer + 100,
		...theme.name === THEMES.LIGHT ? {
			boxShadow: 'none',
			backgroundColor: theme.palette.primary.main
		} : {},
		...theme.name === THEMES.ONE_DARK ? {
			backgroundColor: theme.palette.background.default
		} : {}
	},
	toolbar: {
		minHeight: 64
	}
} ) );

const TopBar: FC<TopBarProps> = ( {
									  className,
									  onMobileNavOpen,
									  ...rest
								  } ) => {
	const classes = useStyles();
	const auth = useAuth();
	const history = useHistory();
	const [ id, setId ] = useState<string>( null );
	const [ moderator, setModerator ] = useState<boolean>( false );
	const [ gameCreator, setGameCreator ] = useState<boolean>( false );
	const [ guideCreator, setGuideCreator ] = useState<boolean>( false );
	
	useEffect( () => {
		if (history.location.pathname.includes( 'game_creator' )) {
			let match = matchPath( history.location.pathname, {
				path: '/app/management/game_creator/:id',
				exact: true,
				strict: false
			} );
			if (!match) {
				match = matchPath( history.location.pathname, {
					path: '/app/management/game_creator/:id/configuration',
					exact: true,
					strict: false
				} );
			}
			
			// @ts-ignore
			setId( match.params.id );
			setGameCreator( true );
		} else {
			setGameCreator( false );
		}
		
		if (history.location.pathname.includes( 'guide_creator' )) {
			let match = matchPath( history.location.pathname, {
				path: '/app/management/guide_creator/:id',
				exact: true,
				strict: false
			} );
			if (!match) {
				match = matchPath( history.location.pathname, {
					path: '/app/management/guide_creator/:id/configuration',
					exact: true,
					strict: false
				} );
			}
			
			// @ts-ignore
			setId( match.params.id );
			setGuideCreator( true );
		} else {
			setGuideCreator( false );
		}
		
		if (history.location.pathname.includes( 'game_moderator' )) {
			let match = matchPath( history.location.pathname, {
				path: '/app/management/game_moderator/:id',
				exact: true,
				strict: false
			} );
			if (!match) {
				match = matchPath( history.location.pathname, {
					path: '/app/management/game_moderator/:id/media',
					exact: true,
					strict: false
				} );
				
				if (!match) {
					match = matchPath( history.location.pathname, {
						path: '/app/management/game_moderator/:id/activity-feed',
						exact: true,
						strict: false
					} );
					
					if (!match) {
						match = matchPath( history.location.pathname, {
							path: '/app/management/game_moderator/:id/feedback',
							exact: true,
							strict: false
						} );
					}
					
				}
			}
			
			// @ts-ignore
			setId( match.params.id );
			setModerator( true );
		} else {
			setModerator( false );
		}
	}, [ history.location.pathname ] );
	
	
	const lockSystem = (): void => {
		window.localStorage.setItem( 'isLocked', 'true' );
		auth.logout();
	};
	
	return (
		<AppBar
			className={ clsx( classes.root, className ) }
			{ ...rest }
		>
			<Toolbar className={ classes.toolbar }>
				<IconButton
					color="inherit"
					onClick={ onMobileNavOpen }
				>
					<SvgIcon fontSize="small">
						<MenuIcon/>
					</SvgIcon>
				</IconButton>
				{ moderator && (
					<>
						<Button component={ RouterLink } to={ `/app/management/game_moderator/${ id }` }
								color="inherit">Scoreboard</Button>
						<Button component={ RouterLink } to={ `/app/management/game_moderator/${ id }/media` }
								color="inherit">Team
							Submissions</Button>
						<Button component={ RouterLink } to={ `/app/management/game_moderator/${ id }/activity-feed` }
								color="inherit">Activity Feed</Button>
						<Button component={ RouterLink } to={ `/app/management/game_moderator/${ id }/feedback` }
								color="inherit">Feedback</Button>
						{/*<Button color="inherit">Report</Button>*/}
					</>
				) }
				{ gameCreator && (
					<>
						<Button component={ RouterLink } to={ `/app/management/game_creator/${ id }` }
								color="inherit">Creator</Button>
						<Button component={ RouterLink } to={ `/app/management/game_creator/${ id }/configuration` }
								color="inherit">Configuration</Button>
					</>
				) }
				{ guideCreator && (
					<>
						<Button component={ RouterLink } to={ `/app/management/guide_creator/${ id }` }
								color="inherit">Creator</Button>
						<Button component={ RouterLink } to={ `/app/management/guide_creator/${ id }/configuration` }
								color="inherit">Configuration</Button>
					</>
				) }
				<Box
					ml={ 2 }
					flexGrow={ 1 }
				/>
				<UnlockIcon onClick={ _ => lockSystem() }/>
				<Box ml={ 2 }>
					<Account/>
				</Box>
			</Toolbar>
		</AppBar>
	);
};

TopBar.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
	onMobileNavOpen: () => {
	}
};

export default TopBar;
