/* eslint-disable no-use-before-define */
import type { FC, ReactNode } from 'react';
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { PlatformUser, Roles } from '../../../types/PlatformUser';
import { Box, Drawer, Hidden, List, ListSubheader, makeStyles } from '@material-ui/core';
import { Home as DashboardIcon, Users as UsersIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import LoadingScreen from '../../../components/LoadingScreen';

interface NavBarProps {
	onMobileClose: () => void;
	openMobile: boolean;
}

interface Item {
	href?: string;
	icon?: ReactNode;
	info?: ReactNode;
	items?: Item[];
	title: string;
	count?: number;
}

interface Section {
	items: Item[];
	subheader: string;
}

function renderNavItems( {
							 items,
							 pathname,
							 depth = 0
						 }: {
	items: Item[];
	pathname: string;
	depth?: number;
} ) {
	return (
		<List disablePadding>
			{ items.reduce(
				( acc, item ) => reduceChildRoutes( { acc, item, pathname, depth } ),
				[]
			) }
		</List>
	);
}

function reduceChildRoutes( {
								acc,
								pathname,
								item,
								depth
							}: {
	acc: any[];
	pathname: string;
	item: Item;
	depth: number;
} ) {
	const key = item.title + depth;
	
	if (item.items) {
		const open = matchPath( pathname, {
			path: item.href,
			exact: false
		} );
		
		acc.push(
			<NavItem
				depth={ depth }
				icon={ item.icon }
				info={ item.info }
				key={ key }
				open={ Boolean( open ) }
				title={ item.title }
				count={ item.count }
			>
				{ renderNavItems( {
					depth: depth + 1,
					pathname,
					items: item.items
				} ) }
			</NavItem>
		);
	} else {
		acc.push(
			<NavItem
				depth={ depth }
				href={ item.href }
				icon={ item.icon }
				info={ item.info }
				key={ key }
				title={ item.title }
				count={ item.count }
			/>
		);
	}
	
	return acc;
}

const useStyles = makeStyles( () => ( {
	mobileDrawer: {
		width: 256
	},
	desktopDrawer: {
		width: 256,
		top: 64,
		height: 'calc(100% - 64px)'
	},
	avatar: {
		cursor: 'pointer',
		width: 64,
		height: 64
	},
	mainNav: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	}
} ) );

const NavBar: FC<NavBarProps> = ( { onMobileClose, openMobile } ) => {
	const classes = useStyles();
	const location = useLocation();
	const [ user, setUser ] = useState<PlatformUser>();
	const [ sections, setSections ] = useState<Array<Section>>( [] );
	
	useEffect( () => {
		setUser( JSON.parse( window.localStorage.getItem( 'user' ) ) );
	}, [] );
	
	useEffect( () => {
		if (!user) {
			return;
		}
		
		const newSections = [];
		
		if (user.roleId === Roles.ADMIN || user.roleId === Roles.SUPER_ADMIN) {
			newSections.push( {
				subheader: 'Main'.toUpperCase(),
				items: [
					{
						title: 'Dashboard',
						icon: DashboardIcon,
						href: '/app/management/dashboard'
					}
				]
			} );
			
			newSections.push( {
				subheader: 'Management'.toUpperCase(),
				items: []
			} );
			
			newSections.find( section => section.subheader.toUpperCase() === 'Management'.toUpperCase() ).items.push( {
				title: 'Users',
				icon: UsersIcon,
				href: '/app/management/user'
			} );
			
			if (user.roleId === Roles.SUPER_ADMIN) {
				newSections.find( section => section.subheader.toUpperCase() === 'Management'.toUpperCase() ).items.push( {
					title: 'Vendors',
					icon: UsersIcon,
					href: '/app/management/vendor'
				} );
			}
		}
		
		setSections( newSections );
	}, [ user ] );
	
	useEffect( () => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ location.pathname ] );
	
	if (!user) {
		return ( <LoadingScreen/> );
	}
	
	const content = (
		<Box
			height="100%"
			display="flex"
			flexDirection="column"
		>
			<PerfectScrollbar className={ classes.mainNav } options={ { suppressScrollX: true } }>
				<Hidden mdUp>
					<Box
						p={ 2 }
						display="flex"
						justifyContent="center"
					>
						<RouterLink to="/">
							<Logo/>
						</RouterLink>
					</Box>
				</Hidden>
				<Box p={ 2 }>
					{ sections.map( ( section ) => (
						<List
							key={ section.subheader }
							subheader={ (
								<ListSubheader
									disableGutters
									disableSticky
								>
									{ section.subheader }
								</ListSubheader>
							) }
						>
							{ renderNavItems( {
								items: section.items,
								pathname: location.pathname
							} ) }
						</List>
					) ) }
				</Box>
			</PerfectScrollbar>
		</Box>
	);
	
	return (
		<>
			<Hidden mdUp>
				<Drawer
					anchor="left"
					classes={ { paper: classes.mobileDrawer } }
					onClose={ onMobileClose }
					open={ openMobile }
					variant={ 'temporary' }
				>
					{ content }
				</Drawer>
			</Hidden>
			<Hidden smDown>
				<Drawer
					anchor="left"
					classes={ { paper: classes.desktopDrawer } }
					open={ openMobile }
					variant={ 'persistent' }
				>
					{ content }
				</Drawer>
			</Hidden>
		</>
	);
};

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool
};

export default NavBar;
