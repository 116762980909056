import type { FC } from 'react';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';

interface LogoProps {
	[key: string]: any;
}

const useStyles = makeStyles({
	container: {
		width: 40,
		height: 40,
		background: '#FFFFFF',
		borderRadius: 40,
		padding: '.3rem',

		'& > img': {
			width: '100%',
			height: '100%'
		}
	}
});

const Logo: FC<LogoProps> = (props) => {
	const classes = useStyles();

	return (
		<Paper elevation={ 8 } className={ classes.container }>
			<img
				alt="Logo"
				src="/static/logo.svg"
				{ ...props }
			/>
		</Paper>
	);
};

export default Logo;
