import type { FC } from 'react';
import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
import axios from './utils/axios';

const jss = create({ plugins: [ ...jssPreset().plugins, rtl() ] });
const history = createBrowserHistory();

const App: FC = () => {
	const { settings } = useSettings();
	const { enqueueSnackbar } = useSnackbar();

	const theme = createTheme({
		direction: settings.direction,
		responsiveFontSizes: settings.responsiveFontSizes,
		theme: settings.theme
	});

	useEffect(() => {
		axios.interceptors.response.use(
			(response) => response,
			(error) => {
				if (error?.hasOwnProperty('response') && error?.response?.hasOwnProperty('status') && error?.response?.hasOwnProperty('data')) {
					enqueueSnackbar(error?.response?.data?.hasOwnProperty('errors') ? error?.response?.data?.errors[0]?.msg : 'Something went wrong.', {
						variant: 'error'
					});
				}

				return Promise.reject((error?.response && error?.response?.data) || 'Something went wrong');
			}
		);
	}, []);

	return (
		<ThemeProvider theme={ theme }>
			<StylesProvider jss={ jss }>
				<MuiPickersUtilsProvider utils={ MomentUtils }>
					<SnackbarProvider
						dense
						maxSnack={ 3 }
					>
						<Router history={ history }>
							<AuthProvider>
								<GlobalStyles />
								<ScrollReset />
								{ renderRoutes(routes) }
							</AuthProvider>
						</Router>
					</SnackbarProvider>
				</MuiPickersUtilsProvider>
			</StylesProvider>
		</ThemeProvider>
	);
};

export default App;
