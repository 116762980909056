import type { FC, ReactNode } from 'react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import type { Theme } from 'src/theme';
import NavBar from './NavBar';
import TopBar from './TopBar';
import clsx from 'clsx';

interface DashboardLayoutProps {
  children?: ReactNode
}

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
    // [theme.breakpoints.up('md')]: {
    // 	paddingLeft: 256
    // }
  },

  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: 0
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: drawerWidth
  }
}));

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const [ isMobileNavOpen, setMobileNavOpen ] = useState<boolean>(true);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
      <div className={ classes.root }>
        <TopBar onMobileNavOpen={ () => setMobileNavOpen(!isMobileNavOpen) } />
        <NavBar
            onMobileClose={ () => setMobileNavOpen(!isMobileNavOpen) }
            openMobile={ isMobileNavOpen }
        />
        <div className={ classes.wrapper }>
          <div className={ classes.contentContainer }>
            <div className={ clsx(classes.content, {
              [classes.contentShift]: isMobileNavOpen && matches
            }) }>
              { children }
            </div>
          </div>
        </div>
      </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
