import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

type Routes = {
	exact?: boolean;
	path?: string | string[];
	guard?: any;
	layout?: any;
	component?: any;
	routes?: Routes;
}[];

export const renderRoutes = ( routes: Routes = [] ): JSX.Element => (
	<Suspense fallback={ <LoadingScreen/> }>
		<Switch>
			{ routes.map( ( route, i ) => {
				const Guard = route.guard || Fragment;
				const Layout = route.layout || Fragment;
				const Component = route.component;
				
				return (
					<Route
						key={ i }
						path={ route.path }
						exact={ route.exact }
						render={ ( props ) => (
							<Guard>
								<Layout>
									{ route.routes
										? renderRoutes( route.routes )
										: <Component { ...props } /> }
								</Layout>
							</Guard>
						) }
					/>
				);
			} ) }
		</Switch>
	</Suspense>
);

const routes: Routes = [
	{
		exact: true,
		path: '/404',
		component: lazy( () => import('src/views/errors/NotFoundView') )
	},
	{
		exact: true,
		guard: GuestGuard,
		path: '/login',
		component: lazy( () => import('src/views/auth/LoginView') )
	},
	{
		exact: true,
		guard: GuestGuard,
		path: '/forgot-password',
		component: lazy( () => import('src/views/auth/ForgotPasswordView') )
	},
	{
		exact: true,
		guard: GuestGuard,
		path: '/reset-password',
		component: lazy( () => import('src/views/auth/ResetPasswordView') )
	},
	{
		path: '/app',
		guard: AuthGuard,
		layout: DashboardLayout,
		routes: [
			{
				exact: true,
				path: '/app/management/profile',
				component: lazy( () => import('src/views/profile') )
			},
			{
				exact: true,
				path: '/app/management/dashboard',
				component: lazy( () => import('src/views/dashboard/DashboardListView') )
			},
			{
				exact: true,
				path: '/app/management/game_creator/:id',
				component: lazy( () => import('src/views/game_creator/GameCreatorView') )
			},
			{
				exact: true,
				path: '/app/management/game_creator/:id/configuration',
				component: lazy( () => import('src/views/game_creator/ConfigurationView') )
			},
			{
				exact: true,
				path: '/app/management/guide_creator/:id',
				component: lazy( () => import('src/views/guide_creator/GuideCreatorView') )
			},
			{
				exact: true,
				path: '/app/management/guide_creator/:id/configuration',
				component: lazy( () => import('src/views/guide_creator/ConfigurationView') )
			},
			{
				exact: true,
				path: '/app/management/guide_moderator/:id',
				component: lazy( () => import('src/views/guide_moderator/ModeratorView') )
			},
			{
				exact: true,
				path: '/app/management/game_moderator/:id',
				component: lazy( () => import('src/views/game_moderator/ModeratorView') )
			},
			{
				exact: true,
				path: '/app/management/game_moderator/:id/media',
				component: lazy( () => import('src/views/game_moderator/MediaArchiveView') )
			},
			{
				exact: true,
				path: '/app/management/game_moderator/:id/activity-feed',
				component: lazy( () => import('src/views/game_moderator/ActivityFeedView') )
			},
			{
				exact: true,
				path: '/app/management/game_moderator/:id/feedback',
				component: lazy( () => import('src/views/game_moderator/FeedbackView') )
			},
			{
				exact: true,
				path: '/app/management/user',
				component: lazy( () => import('src/views/user/UserListView') )
			},
			{
				exact: true,
				path: '/app/management/user/create',
				component: lazy( () => import('src/views/user/UserCreateView') )
			},
			{
				exact: true,
				path: '/app/management/user/:userId/edit',
				component: lazy( () => import('src/views/user/UserEditView') )
			},
			{
				exact: true,
				path: '/app/management/vendor',
				component: lazy( () => import('src/views/vendor/VendorListView') )
			},
			{
				exact: true,
				path: '/app/management/vendor/create',
				component: lazy( () => import('src/views/vendor/VendorCreateView') )
			},
			{
				exact: true,
				path: '/app/management/vendor/:id/edit',
				component: lazy( () => import('src/views/vendor/VendorEditView') )
			},
			{
				exact: true,
				path: '/app/management',
				component: () => <Redirect to="/app/management/dashboard"/>
			},
			{
				exact: true,
				path: '/app',
				component: () => <Redirect to="/app/management/dashboard"/>
			},
			{
				component: () => <Redirect to="/404"/>
			}
		]
	},
	{
		path: '*',
		routes: [
			{
				exact: true,
				path: '/',
				component: () => <Redirect to={ '/app' }/>
			},
			{
				component: () => <Redirect to="/404"/>
			}
		]
	}
];

export default routes;
