import { API_URL } from '../constants';

const parseAvatar = ( avatar: string ): string => {
	if (avatar.includes( 'http' )) {
		return avatar;
	}
	
	return `${ API_URL }/${ avatar }`;
};

export default parseAvatar;